<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex align-items-center justify-center pb-1">
          <feather-icon icon="UserIcon" size="16" />
          <h4 class="pl-1" style="padding-top: 8px;">
            {{ $t('general_info') }}
          </h4>
        </div>
        <validation-observer ref="remoteguiValidation">
          <b-row>
            <b-col cols="4">
              <vi-select
                :options="devices"
                v-model="remotegui.deviceId"
                :label="$t('device')"
              ></vi-select>
            </b-col>
            <b-col cols="4"
              ><vi-input
                rules="required"
                v-model="remotegui.username"
                :label="$t('username')"
              ></vi-input
            ></b-col>
            <b-col cols="4"
              ><vi-input
                rules="required"
                v-model="remotegui.password"
                :label="$t('password')"
              ></vi-input
            ></b-col>
          </b-row>
        </validation-observer>
        <b-button @click="confirm" variant="primary">{{ $t('save') }}</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import ViInput from '@/views/components/ViInput.vue';
import Permissions from './Permissions.vue';
import { ValidationObserver } from 'vee-validate';
import { onMounted, watch, ref } from '@vue/composition-api';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';
import ViSelect from '@/views/components/ViSelect.vue';

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    Permissions,
    ViSelect,
    ValidationObserver,
  },
  setup(props, context) {
    const toastInstance = useToast();

    const remoteguiId = context.root.$route.params.id;

    const remoteguiValidation = ref(null);

    const remotegui = ref({
      deviceId: null,
      username: null,
      password: null,
      userId: context.root.$route.params.userId,
    });

    const devices = ref([]);

    onMounted(async () => {
      if (remoteguiId) {
        const response = await store.dispatch(
          'remotegui/GET_REMOTE_GUI',
          remoteguiId,
        );
        remotegui.value = response.data;
      }

      const r = await store.dispatch('devices/GET_DEVICES', {});
      devices.value = r.data.map(d => {
        return { label: d.name, code: d.id };
      });
    });

    const confirm = async () => {
      const validation = await remoteguiValidation.value.validate();
      if (validation) {
        const response = await store.dispatch(
          remoteguiId
            ? 'remotegui/UPDATE_REMOTE_GUI'
            : 'remotegui/CREATE_REMOTE_GUI',
          {
            remoteguiId,
            remotegui: remotegui.value,
          },
        );
        if (response) {
          successToast(
            toastInstance,
            i18n.t('success'),
            remoteguiId
              ? i18n.t('remotegui_updated')
              : i18n.t('remotegui_created'),
          );
          context.root.$router.push(
            '/settings/users/' + context.root.$route.params.userId,
          );
        }
      }
    };

    return { remotegui, remoteguiId, confirm, remoteguiValidation, devices };
  },
};
</script>

<style></style>
